<template>
  <div id="vacationRequestView" class="text-left">
    <div class="row">
      <div class="col">
        <h4>vacation entry</h4>
        <transition name="slide-fade">
          <app-alert v-if="alert.message" :type="alert.type" :message="alert.message" @alertclose="closeMessage" />
        </transition>
        <ul class="error">
          <li v-for="error in errors">
            {{ error.message }}
          </li>
        </ul>
        <app-modal :show="showApprovalOrRejectionModal" :static="false" size="medium" :title="selectedAction.description + ' requests'">
          <form id="actionForm" onsubmit="event.preventDefault();">
            <fieldset v-bind:disabled="isActioningRequests">
              <div class="form-group row mt-2">
                <!--<span class="font-weight-bold">Are you sure you want to {{ selectedAction.description }} this requst?</span>-->
                <span class="font-weight-bold">comment</span>
                <textarea v-model="actionComment" rows="5" class="form-control" v-bind:required="selectedAction !== actions.Approve"></textarea>
                <div class="invalid-feedback">
                  Please enter comments for this action.
                </div>
              </div>
            </fieldset>
          </form>
          <template v-slot:footer>
            <button class="btn btn-danger d-inline mr-2" @click.prevent="onDismissAction" v-bind:disabled="isActioningRequests">No</button>
            <button class="btn btn-primary d-inline mr-2" v-bind:class="{ spin: isActioningRequests }" @click.prevent="onActionRequestsConfirmed" v-bind:disabled="isActioningRequests">Yes<span class="spinner"></span></button>
          </template>
        </app-modal>
        <fieldset v-if="!isCompleted" v-bind:disabled="disableFields">
          <div>
            <employee-info-display v-if="employeeInfo.employeeId" :displayMode="true" :employeeInfo="employeeInfo" :vacationMaster="vacationMaster" :requestCanBeRaisedForEmployee="leaveRequestCanBeRaisedForEmployee" :unScheduledVacationDaysToDisplay="vacationMaster.unScheduledVacationDays" :unScheduledRnRDaysToDisplay="vacationMaster.unScheduledRnRDays"></employee-info-display>
          </div>
        </fieldset>
      </div>
    </div>
    <div class="row" v-if="requests.length">
      <div class="col">
        <data-table id='requests-table' :headers='tableHeaders' :items='requests'>
          <template v-slot:item.isChecked="{ item }">
            <input type="checkbox" v-model="item.isChecked" />
          </template>
          <template v-slot:item.requestDate="{ item }">
            {{ item.requestDate | toShortDateString }}
          </template>
          <template v-slot:item.startDate="{ item }">
            {{ item.startDate | toShortDateString }}
          </template>
          <template v-slot:item.resumptionDate="{ item }">
            {{ item.resumptionDate | toShortDateString }}
          </template>
          <template v-slot:item.status="{ item }">
            {{ item.status | toRequestStatusDescription }}
          </template>
        </data-table>
      </div>
      <div class="offset-3 mt-4">
        <button class="btn btn-secondary d-inline mr-2" v-if="showEditButton" @click="editRequest">Edit &#x270E;</button>
        <button class="btn btn-primary d-inline mr-2" v-if="showApproveButton" @click="approveRequests" title="Approve requests">Approve &#x2713;</button>
        <button class="btn btn-danger d-inline mr-2" v-if="showCancelButton" @click="cancelRequests" title="Cancel request">Cancel &#x2716;</button>
        <button class="btn btn-danger d-inline mr-2" v-else-if="showRejectButton" @click="rejectRequests" title="Reject request">Reject &#x2716;</button>
        <button class="btn btn-warning d-inline mr-2" v-if="showReturnButton" @click="returnRequests" title="Return request">Return &#x23CE;</button>
      </div>
    </div>
    <div class="row" v-if="requests.length">
      <div class="col">
        <h5>Action/Log History Details</h5>
        <RequestActionList v-if="checkedRequests.length == 1" :requestActions="checkedRequests[0].requestActions"></RequestActionList>
        <p v-else class="text-center font-weight-bold">Select only one request to show history </p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

  .action-btn {
    cursor: pointer;
    font-size: 30px;
  }

  .slide-fade-enter-active {
    transition: all .5s ease;
  }

  .slide-fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  .error {
    text-align: left;
    color: #ac0c0c;
    list-style: none;
  }

  fieldset {
    border: 0;
  }

  button {
    position: relative;
    transition: all 1s;
  }

  .spin {
    padding-left: 2.5em;
    display: block;
  }

  .spin .spinner {
    left: -.6em;
    top: .4em;
    width: 2.5em;
    display: block;
    position: absolute;
  }

  /* spinner animation */
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  /* The actual spinner element is a pseudo-element */
  .spin .spinner::before {
    content: "";
    width: 1.5em; /* Size of the spinner */
    height: 1.5em; /* Change as desired */
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    border: solid .35em #000; /* Thickness/color of spinner track */
    border-bottom-color: #555; /* Color of variant spinner piece */
    animation: .8s linear infinite spinner; /* speed of spinner */
    transform: translate(-50%, -50%);
    will-change: transform;
  }

  /* optional, but it will affect the size if changed */
  *, *::before, *::after {
    box-sizing: border-box;
  }
</style>

<script>
  import store from '@/store'
  import { mapState } from 'vuex'

  import Enums from '@/utils/enums'
  import { LeaveTypes } from '@/utils/constants'

  import { toRequestStatusDescription } from '@/filters/enum'
  import { toShortDateString } from '@/filters'

  import { vacationMasterService } from '@/services'

  import eventBus from '@/utils/eventBus'
  import Events from '@/utils/events'

  import EmployeeInfoDisplay from '@/components/vacation/EmployeeInfoDisplay'
  import RequestList from '@/components/leaveRequests/RequestList'
  import DataTable from '@/components/ui/DataTable'
  import RequestActionList from '@/components/leaveRequests/RequestActionList'

  import GeneralMixin from '@/mixins/GeneralMixin'
  import AccessControlMixin from '@/mixins/AccessControlMixin'
  import LeaveRequestMixin from '@/mixins/leaveRequest'
  import LeaveRequestActionsMixin from '@/mixins/leaveRequest/requestActions'


  export default {
    name: 'VacationRequestListView',
    components: {
      EmployeeInfoDisplay,
      DataTable,
      RequestList,
      RequestActionList
    },
    mixins: [GeneralMixin, AccessControlMixin, LeaveRequestMixin, LeaveRequestActionsMixin],
    data: () => ({
      requests: [],
      vacationYear: null,
      vacationMaster: {
        id: null,
        vacationYear: null,
        vacationDaysDue: 0,
        travelDays: 0,
        vacationAndTravelDays: 0,
        carriedOverDays: 0,
        unscheduledVacationDays: 0,
        unscheduledRnRDays: 0,
        buyOutDays: 0,
        fieldVacationOption: '',
        vacationAllowanceRequest: {
          id: null,
          payMonth: 12,
          paymentStatus: 0,
          allowanceType: 0
        },
        version: null
      },
      employeeInfo: {
        employeeId: '',
        employeeNo: '',
        fullName: '',
        employmentDate: '',
        sbuStartDate: '',
        locationName: '',
        locationId: '',
        workScheduleName: '',
        workScheduleId: '',
        employeeTypeId: '',
        employeeTypeName: '',
        supervisorName: '',
        payrollCountryId: null,
        payrollCountry: null,
        alternateSupervisorName: '',
        isRegularEmployee: null,
        isExpatriateEmployee: null,
        isFieldEmployee: null,
        isShiftEmployee: null,
        isOnForeignService: null
      },
      additionalData: {
        vacationYears: [],
        startingUscheduledVacationDays: 0
      },
      leaveType: LeaveTypes.Vacation,
      tableHeaders: [
        {
          name: 'isChecked',
          title: ' '
        },
        {
          name: 'requestDate',
          title: 'request date'
        },
        {
          name: 'startDate',
          title: 'start date'
        },
        {
          name: 'resumptionDate',
          title: 'resumption date'
        },
        {
          name: 'duration',
          title: 'duration'
        },
        {
          name: 'holidays',
          title: 'holidays'
        },
        {
          name: 'status',
          title: 'status'
        }
      ],
      showApprovalOrRejectionModal: false,
      isActioningRequests: false
    }),
    filters: {
      toRequestStatusDescription,
      toShortDateString
    },
    computed: {
      ...mapState({
        currentUser: state => state.userModule.currentUser,
      }),
      checkedRequests() {
        return this.requests.filter(request => request.isChecked)
      },
      showEditButton() {
        return this.checkedRequests.length == 1 && this.canEditRequest(this.checkedRequests[0])
      },
      showCancelButton() {
        return (this.checkedRequests.length > 0 && (this.checkedRequests.length == this.checkedRequests.filter(request => this.canCancelRequest(request)).length))
      },
      showApproveButton() {
        return (this.checkedRequests.length > 0 && (this.checkedRequests.length == this.checkedRequests.filter(request => this.canApproveRequest(request)).length))
      },
      showRejectButton() {
        return (this.checkedRequests.length > 0 && (this.checkedRequests.length == this.checkedRequests.filter(request => this.canRejectRequest(request)).length))
      },
      showReturnButton() {
        return (this.checkedRequests.length > 0 && (this.checkedRequests.length == this.checkedRequests.filter(request => this.canReturnRequest(request)).length))
      }
    },
    methods: {
      fetchExistingData() {
        const self = this
        eventBus.$emit(Events.LongOperationStarted, '')
        vacationMasterService.getVacationMasterForVacationRequest(self.$route.params.id).then((vacationMaster) => {
          if (vacationMaster) {
            const employee = vacationMaster.vacationRequests[0].employeeInfo
            self.setData(employee, vacationMaster, vacationMaster.vacationRequests)
          }
        }).catch((error) => {
          self.showErrorMessage(error)
        }).finally(() => {
          eventBus.$emit(Events.LongOperationCompleted)
        })
      },
      setData(employee, vacationMaster, requests) {
        this.setEmployeeData(employee)
        this.employeeInfo.employmentDate = employee.employmentDate
        this.employeeInfo.sbuStartDate = employee.sbuStartDate
        this.employeeInfo.payrollCountryId = employee.payrollCountryId
        this.employeeInfo.payrollCountry = employee.payrollCountry
        this.vacationMaster.id = vacationMaster.id
        this.vacationMaster.vacationYear = vacationMaster.vacationYear
        this.vacationMaster.vacationDaysDue = vacationMaster.vacationDaysDue
        this.vacationMaster.travelDays = vacationMaster.travelDays
        this.vacationMaster.vacationAndTravelDays = vacationMaster.vacationAndTravelDays
        this.vacationMaster.carriedOverDays = vacationMaster.carriedOverDays
        this.vacationMaster.unScheduledVacationDays = vacationMaster.unscheduledVacationDays
        this.vacationMaster.unscheduledRnRDays = vacationMaster.unscheduledRnRDays
        this.vacationMaster.buyOutDays = vacationMaster.fieldVacationOption === Enums.FieldVacationScheduleOption.Schedule_CashInLieu || this.employeeInfo.workScheduleId === Enums.WorkSchedule.WS28x28.value ? vacationMaster.unscheduleVacationDays : (vacationMaster.fieldVacationOption === Enums.FieldVacationScheduleOption.Schedule14_Days ? Math.min(vacationMaster.vacationDays + vacationMaster.travelDays - 14, vacationMaster.unscheduleVacationDays) : 0)
        this.vacationMaster.version = vacationMaster.version
        this.vacationMaster.vacationAllowanceRequest.payMonth = Enums.Months.December.value
        requests.forEach(request => {
          request.isChecked = (request.id == this.$route.params.id)
        })
        this.requests = [...requests]
      },
      editRequest() {
        const request = this.checkedRequests[0]
        this.$router.push({ name: `editVacationRequest`, params: { id: request.id } })
      },
      cancelRequests() {
        this.actionRequests(Enums.Actions.Cancel, Enums.RequestStatus.Cancelled)
      },
      approveRequests() {
        this.actionRequests(Enums.Actions.Approve, Enums.RequestStatus.Approved)
      },
      rejectRequests() {
        this.actionRequests(Enums.Actions.Reject, Enums.RequestStatus.Rejected)
      },
      returnRequests() {
        this.actionRequests(Enums.Actions.Return, Enums.RequestStatus.Returned)
      },
      actionRequests(action, nextRequestStatus) {
        this.selectedAction = action
        this.nextRequestStatus = nextRequestStatus
        this.showApprovalOrRejectionModal = true
      },
      onActionRequestsConfirmed() {
        const self = this
        const requestForm = document.getElementById('actionForm')
        if (requestForm.checkValidity() === false) {
          requestForm.classList.add('was-validated');
        }
        else {
          self.isActioningRequests = true
          store.dispatch(self.selectedAction.actionName, { requestIds: this.checkedRequests.map(a => a.id), requestType: LeaveTypes.Vacation, comment: self.actionComment })
            .then(() => {
              self.showSuccessMessage(`request ${self.selectedAction.postActionText} successfully`)
              //self.selectedRequestToAction.status = self.nextRequestStatus.value
              self.actionComment = null
              self.showApprovalOrRejectionModal = false
              self.selectedRequestToAction = null
            })
            .catch((error) => {
              self.showErrorMessage(error)
            }).finally(() => {
              self.isActioningRequests = false
            })
        }
      },
      onDismissAction() {
        this.actionComment = null
        this.showApprovalOrRejectionModal = false
      },
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.fetchExistingData()
      })
    }
  }
</script>
